import React from 'react';
import styled from 'styled-components';
import { Container, Wrapper } from './ContentContainer.styled';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  img?: string;
}

const DWrapper = styled(Wrapper)`
  background-color: ${({ theme }) => theme.black};
`;

const Dark: React.FunctionComponent<Props> = ({
  children,
  img,
  ...divProps
}: Props) => {
  return (
    <DWrapper {...divProps} img={img}>
      <Container>{children}</Container>
    </DWrapper>
  );
};

export default Dark;
