import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  max-width: 1300px;
  margin: auto;
  padding: 1rem;

  @media only screen and (min-width: 768px) {
    padding: 2rem;
  }
`;

interface WrapperProps {
  img?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ img }) => {
    if (img) {
      return `background-image: url(${img});`;
    }
  }}
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
`;
