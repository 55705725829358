import styled from 'styled-components';

export const Heading = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.fontHeader};

  font-size: 2rem;
  line-height: 2rem;

  margin: 1rem auto 1.5rem;

  text-align: center;

  @media only screen and (min-width: 768px) {
    font-size: 2.25rem;
    line-height: 3.25rem;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 3rem;
    line-height: 4rem;
  }
`;

export const Header = styled.div`
  color: ${({ theme }) => theme.fontColour};
  font-family: ${({ theme }) => theme.fontHeader};
  margin: 0.5rem 0 1.5rem;

  font-size: 1.5rem;
  line-height: 2rem;

  @media only screen and (min-width: 768px) {
    font-size: 2.1rem;
    line-height: 3rem;
  }
`;

export const Paragraph = styled.div`
  color: ${({ theme }) => theme.grey300};
  font-family: ${({ theme }) => theme.fontBody};

  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.5px;

  margin-bottom: 1.25rem;

  @media only screen and (min-width: 1024px) {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
`;

export const Image = styled.img`
  margin-bottom: 1rem;
  width: 100%;
`;

export const Text = styled.div`
  margin: 1rem auto 1.5rem;
  max-width: 768px;

  a {
    color: ${({ theme }) => theme.secondary300};
    display: inline-flex;
    align-items: center;

    font-weight: normal;
  }
`;

export const NumberedList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  padding: 2rem 0rem 1rem 1rem;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
`;

export const ListNumber = styled.div`
  border: 4px solid ${({ theme }) => theme.primary500};
  border-radius: 50%;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: 32px;
  width: 32px;
  min-height: 32px;
  min-width: 32px;

  margin-right: 0.5rem;
`;

export const ListContent = styled.div``;

export const MenuGap = styled.div`
  height: 80px;
`;

export const Gap = styled.div`
  padding: 1rem;

  @media only screen and (min-width: 768px) {
    padding: 1.5rem;
  }

  @media only screen and (min-width: 1024px) {
    padding: 2rem;
  }
`;

export const Yellow = styled.span`
  color: ${({ theme }) => theme.primary500};
`;
