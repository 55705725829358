import React from 'react';
import { Container, Wrapper } from './ContentContainer.styled';

import Grey from './Grey';
import Dark from './Dark';
import NextGen from './NextGen';
import Reinventing from './Reinventing';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  img?: string;
}

const ContentContainer: React.FunctionComponent<Props> = ({
  children,
  img,
  ...divProps
}: Props) => {
  return (
    <Wrapper img={img}>
      <Container {...divProps}>{children}</Container>
    </Wrapper>
  );
};

export default Object.assign(ContentContainer, {
  Dark,
  Grey,
  NextGen,
  Reinventing,
});
