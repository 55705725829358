import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Alert, Button, Typography } from 'amphitheatre';

import Layout from 'src/Layout';

import Container from 'src/Components/ContentContainer';
import ToTop from 'src/Components/ToTop';

import * as Base from 'src/page-styles/base.styled';

import Step1Image from 'src/assets/images/tutorials/step5/pic1.png';
import Step2Image from 'src/assets/images/tutorials/step5/pic2.png';

const { Header, Paragraph } = Typography;

const BtnGroup = styled.div``;

function Step1(): JSX.Element {
  return (
    <Layout>
      <ToTop />
      <Container.Dark>
        <Base.MenuGap />
        <Base.Heading>Tutorial 5: Overpass Data</Base.Heading>
        <Base.Text>
          <Header level={3}>Step 1</Header>
          <Base.Image src={Step1Image} />
          <Paragraph>
            A P.I.G.I. save scene must be included in your submission. To do
            this, click the scene button on the menu wheel.
          </Paragraph>
          <Base.Gap />
          <Header level={3}>Step 2</Header>
          <Base.Image src={Step2Image} />
          <Paragraph>
            This will bring up a dialog box, name your scene and click save.
            Your scene file should appear in the greyed out area above the input
            box, in our example pigi_test.json is now saved.
          </Paragraph>
          <Paragraph>
            In order to access your saved scene file, search in:
          </Paragraph>
          <Paragraph>
            <i>
              C:\Users\(account)\AppData\LocalLow\SaberAstronautics\PIGI\SaveData
            </i>
          </Paragraph>
          <Paragraph>The save file will be in .JSON format.</Paragraph>
          <div style={{ margin: '1rem 0' }}>
            <Alert warning>
              In order to find the ‘AppData’ folder, you must allow hidden files
              / folders to be shown.
              <a
                href="https://support.microsoft.com/en-gb/help/14201/windows-show-hidden-files"
                target="_blank"
                rel="noopener noreferrer"
              >
                How to do it.
              </a>
            </Alert>
          </div>
          <BtnGroup>
            <Link to="/tutorials/tutorial4" style={{ textDecoration: 'none' }}>
              <Button ghost>Prev</Button>
            </Link>
          </BtnGroup>
        </Base.Text>
      </Container.Dark>
    </Layout>
  );
}

export default Step1;
