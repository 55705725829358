import React from 'react';
import styled from 'styled-components';
import { Container, Wrapper } from './ContentContainer.styled';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  img?: string;
}

const NGWrapper = styled(Wrapper)`
  ${Container} {
    display: flex;
    position: relative;

    .left-content {
      width: 100%;
    }

    img {
      display: none;
    }

    @media only screen and (min-width: 1024px) {
      padding: 4rem 1rem;

      .left-content {
        width: 70%;
      }

      img {
        display: block;
        position: absolute;
        right: 0;
        width: 312px;
        z-index: -1;
      }
    }
  }
`;

const NextGen: React.FunctionComponent<Props> = ({
  children,
  img,
  ...divProps
}: Props) => {
  return (
    <NGWrapper {...divProps} img={img}>
      <Container>{children}</Container>
    </NGWrapper>
  );
};

export default NextGen;
