import React from 'react';
import styled from 'styled-components';
import { Container, Wrapper } from './ContentContainer.styled';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  img?: string;
}

const BContainer = styled(Container)`
  padding: 24px;

  @media only screen and (min-width: 768px) {
    padding: 96px 48px;
  }
`;

const BWrapper = styled(Wrapper)`
  background-color: ${({ theme }) => theme.grey800};
`;

const Grey: React.FunctionComponent<Props> = ({
  children,
  img,
  ...divProps
}: Props) => {
  return (
    <BWrapper {...divProps} img={img}>
      <BContainer>{children}</BContainer>
    </BWrapper>
  );
};

export default Grey;
