import React from 'react';
import styled from 'styled-components';

import { BsChevronDoubleUp } from 'react-icons/bs';

const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.5);
  color: white;
  position: fixed;
  bottom: 5%;
  right: 5%;

  cursor: pointer;
  display: flex;
  align-items: center;

  padding: 0.5rem;
  border-radius: 25px;
`;

const Icon = styled.div`
  background: ${({ theme }) => theme.primary500};
  border-radius: 100%;
  color: black;

  height: 32px;
  width: 32px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled.div`
  display: flex;
  align-items: center;

  font-family: ${({ theme }) => theme.fontBody};
  padding: 0 1rem 0 1rem;
`;

function ToTop(): JSX.Element {
  function onClick() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <Wrapper onClick={onClick}>
      <Icon>
        <BsChevronDoubleUp />
      </Icon>
      <Text>Go to top</Text>
    </Wrapper>
  );
}

export default ToTop;
