import React from 'react';
import styled from 'styled-components';
import { Container, Wrapper } from './ContentContainer.styled';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  img?: string;
}

const RWrapper = styled(Wrapper)`
  ${Container} {
    display: flex;
    flex-flow: column-reverse;

    padding: 4rem 1rem;

    @media only screen and (min-width: 1024px) {
      display: grid;
      column-gap: 64px;
      grid-template-columns: 1fr 1fr;

      padding: 8rem 1rem;
    }
  }
`;

const Reinventing: React.FunctionComponent<Props> = ({
  children,
  img,
  ...divProps
}: Props) => {
  return (
    <RWrapper {...divProps} img={img}>
      <Container>{children}</Container>
    </RWrapper>
  );
};

export default Reinventing;
